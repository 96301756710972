






import { Component, Vue } from 'vue-property-decorator';
import ProfileCOmponent from '@/components/profile/profile.component.vue';

@Component({
  components: {
    ProfileCOmponent,
  },
})
export default class ProfileView extends Vue {}
