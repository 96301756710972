










































































































































import { Component, Vue } from 'vue-property-decorator';
import HomeProvider from '@/providers/home.provider';
import servertranslate from '@/filters/server-translate';
import { ProfileData } from '../../types/general.type';

@Component
export default class ProfileComponent extends Vue {
  profileDate: ProfileData | null = null;

  err = '';

  FoundationValidation = '';

  userName: string = this.$route?.params.username;

  servertranslate = servertranslate;

  seen = false;

  loading = true;

  homeProvider = new HomeProvider();

  mounted() {
    this.loadProfileData();
  }

  async loadProfileData() {
    try {
      this.profileDate = await this.homeProvider.getProfileData(this.userName);
      this.profileDate.image = this.profileDate.image
        ? this.bufferToBase64(this.profileDate.image.data)
        : this.profileDate.image;
      this.seen = !!this.servertranslate(this.profileDate.foundation);
      this.loading = false;
    } catch (err) {
      this.err = err;
      this.loading = false;
    }
  }

  bufferToBase64(buf) {
    const binstr = Array.prototype.map
      .call(buf, (ch) => String.fromCharCode(ch))
      .join('');
    return btoa(binstr);
  }

  openEditPage() {
    const { username } = this.$store.state.auth;
    this.$router.push({
      name: 'profileEdit',
      params: {
        username,
      },
    });
  }
}
